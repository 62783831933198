import { Uploader } from "uploader"; 
import { UploadButton } from "react-uploader";


function PdfcertificationCard() {
    // Initialize once (at the start of your app).
    const uploader = Uploader({
      apiKey: "public_kW15cFs5ZmgK98mkNnyGDmeRfGcG", // Get production API keys from Bytescale
    });
  
    // Configuration options: https://www.bytescale.com/docs/upload-widget/frameworks/react#customize
    // https://www.npmjs.com/package/react-uploader
    const options = { multi: true };
  return (
    <>
      <h2>Certification Upload 2</h2>
      <div>
        <UploadButton
          uploader={uploader}
          options={options}
          onComplete={(files) => alert(files.map((x) => x.fileUrl).join("\n"))}
        >
          {({ onClick }) => <button className="lumi-btn join-call padding-horizontal" onClick={onClick}>Upload a file...</button>}
        </UploadButton>
      </div>
    </>
  );
}

export default PdfcertificationCard;
