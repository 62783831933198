import Accordion from "react-bootstrap/Accordion";
import video from "../../assets/images/img_video.png";
import admin from "../../assets/images/img_admin.png";
import { ReactComponent as StarLike } from "../../assets/icons/star_like.svg";
import BenefitItem from "../../containers/PractitionerSignUp/components/BenefitItem";

const FeatureItem = ({ title, description }) => (
  <div className="flex flex-col">
    <h3 className="font-body text-2xl font-bold  text-lumi-blue py">{title}</h3>
    <p className="text-lumi-body-text font-body font-extralight">
      {description}
    </p>
  </div>
);

const FeatureList = () => (
  <div className="flex-[0.5] ">
    <div className="flex flex-col justify-center">
      <FeatureItem
        title={"Offer online or in-person appointments "}
        description={
          "Utilise our secure telehealth service or see clients in person. Offering support that fits into busy life, reaching women across Australia."
        }
      />
      <FeatureItem
        title={"Join a community of like-minded practitioners"}
        description={"Dedicated to supporting women."}
      />
      <FeatureItem
        title={"Connect with women who need your expertise"}
        description={
          "Thanks to our unique matching tool that pairs you with individuals seeking your specific skills."
        }
      />
      <FeatureItem
        title={"Proudly Australian owned and operated"}
        description={
          "Supporting local practitioners and empowering our community."
        }
      />
      <FeatureItem
        title={"Promote your Medicare services"}
        description={
          "If applicable (invoices provided for clients to claim rebates)."
        }
      />
    </div>
  </div>
);
function PractitionerSignUpBody() {
  return (
    <div className="practitioner-signup-body">
      <div className="bg-[#FEF5F2] rounded-tr-[176px] tablet:rounded-tr-[128px] py-24 px-56 tablet:p-8 my-16 mx-8 items-center flex flex-col gap-4">
        <h1 className="text-5xl text-lumi-blue font-title tablet:text-4xl">
          Designed to empower women
        </h1>
        <p className="text-lumi-gray text-2xl font-light font-body tablet:text-xl leading-9 text-center">
          Our platform is designed to empower women to take charge of their
          wellness and find support that fits their unique journey. Our smart
          matching tool connects your specialised expertise with those who need
          it most, offering flexible online or in-person appointments across
          Australia. Be part of a mission that values mental health and holistic
          well-being, with streamlined client management and marketing support,
          empowering you to make a lasting impact in women's lives.
        </p>
        <StarLike className="h-[48px] w-[48px] tablet:h-[32px] tablet:w-[32px] tablet:mt-0" />
      </div>
      <h1>Perks of being a LumiLife Practitioner</h1>
      <div className="circles-container row m-0">
        <div className="d-flex flex-column justify-content-top align-items-center text-center col-4">
          <div className="circles circle1">
            <div>1</div>
          </div>
          <h2 className="yellow">We champion you</h2>
          <p>
            We'll lift you up and help you to stand out by marketing your unique
            expertise and match you with women who are looking for your skills.
          </p>
        </div>
        <div className="d-flex flex-column justify-content-top align-items-center text-center col-4">
          <div className="circles circle2">
            <div>2</div>
          </div>
          <h2 className="pink">Forge your own path</h2>
          <p>
            Set your own schedule and fees and feel the freedom of working when
            and where you want -from home or on the road.
          </p>
        </div>
        <div className="d-flex  flex-column justify-content-top align-items-center text-center col-4">
          <div className="circles circle3">
            <div>3</div>
          </div>
          <h2 className="rose">Simplified admin</h2>
          <p>
            We take care of scheduling, telehealth access, payments and
            invoicing so you can focus on what you do best.
          </p>
        </div>
      </div>
      <div className="flex desktop:flex-row my-20 tablet:my-8 mx-24 tablet:mx-8 items-center justify-center gap-16 tablet:flex-col ">
        <div className="flex-[0.5]">
          <div className="flex flex-1">
            <div className="flex flex-col">
              <BenefitItem
                title={" It's free to join"}
                subtitle={
                  "Joining our platform is free and you can cancel your profile anytime*"
                }
              />
              <BenefitItem
                title={"Secure video consultations"}
                subtitle={
                  "Ensure privacy and comfort for both you and your clients."
                }
              />
              <BenefitItem
                title={"Support women to shine"}
                subtitle={
                  "Use your skills to empower women and make meaningful change."
                }
              />
            </div>
          </div>
        </div>
        <div className="flex flex-1 justify-center max-w-[800px]">
          <img src={video} className="object-contain " />
        </div>
      </div>
      <div className="flex desktop:flex-row my-20 tablet:my-8 tablet:mt-20 mx-24 tablet:mx-8 items-center justify-center gap-16 tablet:flex-col tablet:flex ">
        <div className="flex flex-1 justify-center max-w-[800px]">
          <img src={admin} className="object-cover" />
        </div>
        <FeatureList />
      </div>

      <div className="faqs">
        <h2>FAQs</h2>
        <Accordion defaultActiveKey="0">
          <Accordion.Item eventKey="0">
            <Accordion.Header>Am I the right one for you?</Accordion.Header>
            <Accordion.Body>
              We charge no monthly fee or joining fees and you can cancel or
              pause your account at anytime. We chage a flat 20% service fee for
              each session booked. This helps keep the platform running and
              enables us to provide support.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>
              Can I pause my account if I’m fully booked?
            </Accordion.Header>
            <Accordion.Body>
              Absolutely! You can pause or cancel your account at any time. This
              flexibility allows you to manage your workload and helps us
              maintain platform support.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2">
            <Accordion.Header>How will I get paid?</Accordion.Header>
            <Accordion.Body>
              After you confirm a session is complete, LumiLife will process the
              payment to you, retaining the service fee. This ensures a smooth
              and straightforward payment process.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="3">
            <Accordion.Header>How much does it cost?</Accordion.Header>
            <Accordion.Body>
              Access to our platform is free and you can cancel anytime.* *It is
              completely free to join and be seen on LumiLife. A 15% service
              fee, calculated based on your provider's rates, is applicable to
              all sessions booked and/or conducted via the LumiLife platform.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="4">
            <Accordion.Header>
              How does the LumiLife Platform operate?
            </Accordion.Header>
            <Accordion.Body>
              LumiLife is a platform that connects independent service providers
              with clients. Each service provider is responsible for the
              services they offer.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="5">
            <Accordion.Header>
              Is LumiLife the right fit for you?
            </Accordion.Header>
            <Accordion.Body>
              If you’re an independent provider looking to reach new clients
              without the hassle of marketing or admin tasks, LumiLife could be
              your ideal partner. We handle the client connections, allowing you
              to focus on delivering great service.
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
    </div>
  );
}

export default PractitionerSignUpBody;
